import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from "./library.module.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import VideoSearch from "../VideoSearch/videoSearch";
import Navigation from "../Nav";
import Loading from "../Loading/loading";
import { getURL, getUser } from "../../helpers/index";

var FA = require("react-fontawesome");
var dateFormat = require("dateformat");
const orderBy = require("lodash/_baseOrderBy");

const url = getURL();

class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      videos: [],
      loading: true,
    };
  }
  getAllRecords = (e) => {
    if (e) e.preventDefault();

    //creating endpoint based on users
    // To filter the content
    let params = {
      user: getUser(),
    };
    // If is an admin show everything
    // If not an admin only show user's content
    if (
      getUser() === "dcj@humanelevation.com" ||
      getUser() === "ecolon@rlfllp.com" ||
      getUser() === "ljg@humanelevation.com" ||
      getUser() === "jmk@humanelevation.com"
    ) {
      params = {};
    }
    axios
      .get(`${url}/get-all`, { params })
      .then((videos) => {
        this.setState({ videos: [...videos.data] });
      })
      .then(() => {
        if (this.state.loading) this.setState({ loading: false });
      });
  };
  update = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  delete = (id, title) => {
    const d = () =>
      axios({
        method: "POST",
        url: `${url}/delete`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
        },
        data: { id },
      })
        .then((res) => {
          this.setState({
            response: res.data,
          });
        })
        .catch((err) => {
          console.log("res", err);
          this.setState({
            error: "Error Occured while uploading the file",
            uploadSuccess: undefined,
          });
        });
    confirmAlert({
      title: "Confirm to Delete",
      message: `You are about to delete the record "${title}". Are you sure you want to proceed?`,
      buttons: [
        {
          label: "Yes",
          //   onClick: () => d()
          onClick: () => {
            d();
            return this.setState((prevState) => ({
              videos: prevState.videos.filter((el) => el.video_id !== id),
            }));
          },
        },
        {
          label: "No",
          onClick: () => false,
        },
      ],
    });
  };
  search = (e) => {
    e.preventDefault();
    let keyword = this.state.keyword || "";
    axios.get(`${url}/get/${keyword}`).then((videos) => {
      this.setState({ videos: [...videos.data] });
    });
  };
  loadContent = (content = []) => {
    let str = [];

    content.map((item) =>
      item.elements
        ? item.elements.map((d) => {
            return str.push(d.value);
          })
        : ""
    );
    return str.join("");
  };
  componentWillMount() {
    this.getAllRecords();
  }
  render() {
    return (
      <div className={styles.library}>
        {this.state.loading && <Loading />}
        <Navigation history={this.props.history} />
        <div className="container">
          <div className="row">
            <div className={`col-md-3 ${styles.searchColumn}`}>
              <form onSubmit={this.search}>
                <VideoSearch
                  update={this.update}
                  keyword={this.state.keyword}
                  search={this.search}
                />

                <a
                  className={styles.searchLink}
                  href="#0"
                  onClick={this.getAllRecords}
                >
                  Get all records
                </a>
                <p className="records-number">
                  <small>
                    Records found <strong>{this.state.videos.length}</strong>
                  </small>
                </p>
              </form>
            </div>
            <div className={`col-md-9 ${styles.libraryList}`}>
              <ul>
                {orderBy(this.state.videos, ["date"], ["desc"]).map(
                  (item, index) => (
                    <li
                      key={index}
                      // className={
                      //   this.loadContent(item.transcription)
                      //     ? "record"
                      //     : "record-pending"
                      // }
                      className={styles.record}
                    >
                      <Link className={styles.deleteBtn} to="#0">
                        <img
                          src="/assets/icons/delete.svg"
                          onClick={() =>
                            this.delete(item.video_id, item.video_title, this)
                          }
                          alt="delete"
                        />
                      </Link>
                      <div className="row">
                        <div className={`col-md-12 ${styles.content}`}>
                          <div className="content">
                            <Link
                              className={styles.title}
                              to={`/player/${item.video_id}`}
                            >
                              <h5>{item.video_title}</h5>
                            </Link>

                            <p className={styles.date}>
                              <FA name="calendar" icon="calendar" />
                              &nbsp;
                              {dateFormat(
                                new Date(item.date),
                                "dddd, mmmm dS, yyyy, h:MM:ss TT"
                              )}
                              <br />
                              <FA name="user" icon="user" />
                              &nbsp;
                              {item.user}
                            </p>
                          </div>
                          {/* <p>
                          {this.loadContent(item.transcription)
                            ? this.loadContent(item.transcription).substring(
                                0,
                                300
                              ) + "..."
                            : "The content is not ready yet..."}
                        </p> */}
                          {/* {this.loadContent(item.transcription) && (
                          <Link
                            className="btn btn-outline-info btn-sm"
                            to={`/player/${item.video_id}`}
                          >
                            Load document
                          </Link>
                        )} */}
                          {/* <Link
                            className="btn btn-outline-info btn-sm"
                            
                          >
                            Load document
                          </Link> */}
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Library;
