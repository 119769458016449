import React, { Component } from "react";
import axios from "axios";
import "./Player.css";
import { withRouter, Prompt } from "react-router-dom";

import {
  isBackwards,
  updateDB,
  isElement,
  updateDBTitle,
  getURL,
} from "./helpers";
import Transcription from "./components/Transcription/transcription";
import Navigation from "./components/Nav";
import debounce from "lodash/debounce";
import Loading from "./components/Loading/loading";

const url = getURL();

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      video: [],
      highlights: [],
      words: [],
      linebreaks: {},
      track: 0,
      keyword: "",
      index: 0,
      loading: true,
      pristine: true,
    };
    this.element = React.createRef();
    this.updateDBTitle = debounce(this.updateDBTitle.bind(this), 1000);
  }
  setPristine = (pristine) => this.setState({ pristine });

  goto = (time, id, e) => {
    document
      .querySelectorAll("span")
      .forEach((el) => el.classList.remove("focus"));

    document
      .querySelectorAll("span")
      .forEach((el) => el.classList.remove("active"));

    e.target.classList.add("focus");
    e.target.classList.add("active");
    this.setState({ currentWord: id });
    for (let item of this.state.words) {
      if (document.getElementById(item).classList.contains("focus")) {
        break;
      }
      let el = document.getElementById(item);
      if (el) {
        el.classList.add("active");
        let prev = el.previousSibling;
        prev.classList.add("active");
      }
    }
    let video = document.getElementById("video");
    video.currentTime = time;
    // video.play();
    this.setState({ currentWord: id });
  };
  updateDBTitle() {
    updateDBTitle(this.state.video.video_id, this.state.video.video_title);
  }
  updateTitle = (e) => {
    const video = this.state.video;
    video.video_title = e.target.value;

    this.setState({ video }, () => this.updateDBTitle());
  };
  stopTracking = () => clearInterval(this.state.track);

  updateIndex = (i) => {
    this.stopTracking();
    const elements = document.querySelectorAll(".active");
    for (let item of elements) {
      item.classList.remove("active");
    }
    this.setState({ index: this.state.words.indexOf(i) });
  };
  track = () => {
    let video = document.getElementById("video");
    for (let item of this.state.elements) {
      if (video.currentTime < item.ts) {
        break;
      }
    }
    let index = this.state.index;
    let previousElement;

    const p = () => {
      const currentTime = video.currentTime;
      const ts = this.state.words[index];

      if (currentTime > ts) {
        let el;
        this.setState({ index: index++ });

        el = document.querySelector(`[ts='${ts}']`);
        if (previousElement) {
          previousElement.classList.remove("active");
        }

        previousElement = el;

        if (el) {
          el.classList.add("active");
        }
      }
    };
    let t = setInterval(p, 100);
    this.setState({ track: t });
  };
  inProcess = () => {
    this.setState({ loading: true });
  };
  processCompleted = () => {
    this.setState({ loading: false });
  };
  updateVideo = () => {
    if (this.state.loading) this.setState({ loading: false });
    // Linebreaks
    for (let item in this.state.linebreaks) {
      for (let i = 0; i < this.state.linebreaks[item]; i++) {
        const el = document.getElementById(item);
        const br = document.createElement("br");
        br.classList.add("line-breaks");

        if (el) el.appendChild(br);
      }
    }
    const elements = [];
    const words = [];
    for (let item of this.state.video.transcription) {
      item.elements.forEach((el) => elements.push(el));
    }

    this.setState({ elements }, () => {
      elements.forEach((item) => {
        if (item.ts) words.push(item.ts.toString());
      });
      this.setState({ words }, () => {
        const highlights = this.state.highlights;

        if (highlights !== undefined && highlights.length !== 0) {
          this.highlight(highlights);
        }
      });
    });

    const time = this.props.match.params.time;

    if (time) {
      document.getElementById("video").currentTime = time;
    }
  };

  search = () => {
    const id = this.props.match.params.id;

    if (id) {
      axios.get(`${url}/get-one/${id}`).then((video) => {
        const elements = [];
        const fillElements = (data) => {
          if (Array.isArray(data)) {
            data.forEach((i) => elements.push(i));
          } else {
            elements.push(data);
          }
        };
        video.data[0].transcription.forEach((item) => {
          fillElements(item.elements);
        });

        document.getElementById("video").src = video.data[0].video;

        const content = [];
        let row = [];
        let a = [];
        let count = 0;

        elements.forEach((item) => {
          count++;
          row.push({
            text: item.value,
            ts: item.ts,
            end_ts: item.end_ts,
            type: item.type,
          });
          if (count % 200 === 0 || count === elements.length) {
            a = [
              ...a,
              {
                type: "p",
                children: [...row],
              },
            ];
            row = [];
          }

          return content.push({
            text: item.value,
            ts: item.ts,
            end_ts: item.end_ts,
            type: item.type,
          });
        });

        this.setState(
          {
            video: video.data[0],
            elements: elements,
            content: video.data[0].content.length ? video.data[0].content : a,
            highlights: video.data[0].highlights,
            linebreaks: video.data[0].linebreaks || {},
          },
          this.updateVideo
        );
      });
    }
  };

  highlight = (highlights) => {
    const container = document.getElementById("transcription-wrapper").children;

    for (let item of container) {
      if (item) item.classList.remove("mark");
    }
    for (let item of container) {
      const id = item.id;

      if (highlights.indexOf(id) > -1) {
        const el = document.getElementById(id);
        if (el) {
          el.classList.add("mark");
          if (el.nextSibling) el.nextSibling.classList.add("mark");
          if (el.previousSibling) el.previousSibling.classList.add("mark");
        }
      }
    }
  };

  componentDidMount() {
    this.stopTracking();
    this.search();
    window.onbeforeunload = (e) => {
      if (!this.state.pristine) {
        e.preventDefault();
        const answer = window.confirm("test");
        if (!answer) {
          return false;
        } else {
          return true;
        }
      }
    };

    let video = document.getElementById("video");
    const time = this.props.match.params.time;
    video.onplay = () => {
      if (time) {
        this.setState({ index: this.state.words.indexOf(time.toString()) });
      }
    };
    video.onchange = () => {
      if (time) {
        this.setState({ index: this.state.words.indexOf(time.toString()) });
      }
    };
  }

  mark = (e) => {
    const container = document.getElementById("transcription-wrapper").children;
    if (window.getSelection() && window.getSelection().baseNode !== null) {
      let a = window.getSelection().baseNode.parentNode;
      let b = window.getSelection().extentNode.parentNode;
      let steps = 0;
      const words = [];

      if (isBackwards()) {
        [a, b] = [b, a];
      }
      if (b.hasOwnProperty("getAttribute")) {
        while (!b.getAttribute("time") && steps < 5) {
          b = b.nextSibling;
          steps++;
        }
      }
      steps = 0;
      if (a.hasOwnProperty("getAttribute")) {
        while (!a.getAttribute("time") && steps < 5) {
          a = a.previousSibling;
          steps++;
        }
      }
      for (let item in container) {
        if (isElement(container[item])) {
          const time = parseFloat(container[item].getAttribute("time"));
          if (
            time >= parseFloat(a.getAttribute("time")) &&
            time <= parseFloat(b.getAttribute("time"))
          ) {
            words.push(container[item].id);
          }
        }
      }

      if (this.state.highlights)
        this.state.highlights.forEach((item) => {
          if (words.indexOf(item) === -1) {
            words.push(item);
          }
        });
      this.setState(
        {
          highlights: words,
        },
        () => {
          updateDB(this.state.video.video_id, this.state.highlights);
          this.highlight(this.state.highlights);
        }
      );
    }
  };

  unmark = (e) => {
    if (window.getSelection() && window.getSelection().baseNode !== null) {
      const container = document.getElementById("transcription-wrapper")
        .children;

      const highlights = this.state.highlights;

      let a = window.getSelection().baseNode.parentNode;
      let b = window.getSelection().extentNode.parentNode;
      let steps = 0;
      const words = [];
      if (isBackwards()) {
        [a, b] = [b, a];
      }
      if (b.hasOwnProperty("getAttribute")) {
        while (!b.getAttribute("time") && steps < 5) {
          b = b.nextSibling;
          steps++;
        }
        steps = 0;
        while (!a.getAttribute("time") && steps < 5) {
          a = a.previousSibling;
          steps++;
        }
      }
      for (let item in container) {
        const isElement = (obj) => {
          try {
            //Using W3 DOM2 (works for FF, Opera and Chrome)
            return obj instanceof HTMLElement;
          } catch (e) {
            //Browsers not supporting W3 DOM2 don't have HTMLElement and
            //an exception is thrown and we end up here. Testing some
            //properties that all elements have (works on IE7)
            return (
              typeof obj === "object" &&
              obj.nodeType === 1 &&
              typeof obj.style === "object" &&
              typeof obj.ownerDocument === "object"
            );
          }
        };

        if (isElement(container[item])) {
          const time = parseFloat(container[item].getAttribute("time"));
          if (
            time >= parseFloat(a.getAttribute("time")) &&
            time <= parseFloat(b.getAttribute("time"))
          ) {
            words.push(container[item].id);
          }
        }
      }

      words.forEach((item) => {
        const index = highlights.indexOf(item);
        if (index > -1) {
          highlights.splice(index, 1);
        }
      });

      this.setState(
        {
          highlights,
        },
        () => {
          this.highlight(this.state.highlights);
          updateDB(this.state.video.video_id, this.state.highlights);
        }
      );
    }
  };

  componentWillUnmount() {
    this.stopTracking();
    document.getElementById("video").pause();
  }

  render() {
    return (
      <div className="container-fluid" style={{ height: "100%" }}>
        <Prompt
          when={!this.state.pristine}
          message="Are you sure you want to leave without saving your changes?"
        />
        {this.state.loading && <Loading />}
        <Navigation history={this.props.history} />
        <div className="row" style={{ height: "100%" }}>
          <div className="col-md-12" style={{ height: "100%" }}>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-7">
                {/* <Toolbar mark={this.mark} unmark={this.unmark} onCopy={copy} /> */}
                <input
                  type="text"
                  id="video-title"
                  className="video-title-text"
                  onChange={this.updateTitle}
                  value={this.state.video.video_title}
                />
                {/* <button onClick={this.updateTitle}>Change title</button> */}
              </div>
            </div>
            <div className="row" style={{ height: "100%" }}>
              <div className="col-md-4 no-print">
                <video
                  onPlaying={() => this.track()}
                  onPause={() => this.stopTracking()}
                  id="video"
                  width="320"
                  height="240"
                  controls
                >
                  <source src={this.state.video} type="video/mp4" />
                  <source src={this.state.video} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="col-md-7" style={{ height: "100%" }}>
                <div
                  id="transcription-wrapper"
                  className="transcription-container"
                  style={{ height: "100%" }}
                >
                  <span />
                  {this.state.content && (
                    <Transcription
                      classname="transcription-list"
                      elements={this.state.elements}
                      content={this.state.content}
                      video_id={this.state.video.video_id}
                      id={this.props.match.params.time}
                      goto={this.goto}
                      words={this.state.words}
                      keyword={this.state.keyword}
                      updateIndex={this.updateIndex}
                      inProcess={this.inProcess}
                      processCompleted={this.processCompleted}
                      setPristine={this.setPristine}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Player);
