import React, { Component } from "react";
import axios from "axios";
import Navigation from "../../components/Nav";
import * as styles from "./FileUpload.module.css";
import { getURL } from "../../helpers/index";
const url = getURL();

export default class Upload extends Component {
  // fileToUpload contains the actual file object
  // uploadSuccess becomes true when the file upload is complete
  state = {
    fileToUpload: undefined,
    uploadSuccess: undefined,
    videoTitle: "",
    error: undefined,
    fileUrl: "",
  };
  update = (e) => {
    let url = e.target.value;
    let dbUrl = url.replace("www", "dl");
    this.setState({ [e.target.name]: dbUrl });
  };
  getUser = () => {
    let obj = {};
    document.cookie
      .split(" ")
      .map((item) => (obj = { [item.split("=")[0]]: item.split("=")[1] }));

    return obj.email;
  };
  push = () => {
    let data = this.state.response;
    data.videoTitle = this.state.videoTitle;
    data.user = this.getUser();
    axios({
      method: "POST",
      url: `${url}/push`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
      },
      data: data,
    })
      .then((res) => {})
      .catch((err) => {
        console.log("res", err);
        this.setState({
          error: "Error Occured while uploading the file",
          uploadSuccess: undefined,
        });
      });
  };
  transcribeFile = () => {
    axios({
      method: "POST",
      url: "https://2e21o7zi81.execute-api.us-east-1.amazonaws.com/push", //Serverless function
      headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
      },
      data: { url: this.state.fileUrl },
    })
      .then((res) => {
        this.setState(
          {
            uploadSuccess: "File is in process",
            response: res.data,
            error: undefined,
          },
          () => this.push()
        );
      })
      .catch((err) => {
        console.log("res", err);
        this.setState({
          error: "Error Occured while uploading the file",
          uploadSuccess: undefined,
        });
      });
  };
  uploadFile() {
    // When the upload file button is clicked,
    // first we need to get the presigned URL
    // URL is the one you get from AWS API Gateway
    axios(`${url}/upload-to-s3?fileName=${this.state.fileToUpload.name}`).then(
      (response) => {
        // Getting the url from response
        const url = response.data.fileUploadURL;

        // Initiating the PUT request to upload file
        axios({
          method: "PUT",
          url: url,
          data: this.state.fileToUpload,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            this.setState({
              uploadSuccess: "File upload successfull",
              error: undefined,
            });
          })
          .catch((err) => {
            this.setState({
              error: "Error Occured while uploading the file",
              uploadSuccess: undefined,
            });
          });
      }
    );
  }
  render() {
    return (
      <div className={styles.fileUploadWrapper}>
        <div className={styles.header}>File Transcription</div>
        <div className={styles.fileUploadCont + " form"}>
          <Navigation history={this.props.history} />

          <div>
            {/* <form className="upload-form">
            <div className="form-group">
              <input
                type="file"
                className="form-control-file"
                id="fileUpload"
                onChange={e => {
                  this.setState({
                    fileToUpload: e.target.files[0]
                  });
                }}
              />
              {this.state.fileToUpload ? (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={e => {
                    this.uploadFile();
                  }}
                >
                  Upload your file
                </button>
              ) : null}
              <div>
                <span>
                  {this.state.uploadSuccess ? "File Upload Successfully" : ""}
                </span>
              </div>
            </div>
          </form> */}

            <form className="upload-form">
              <div className="form-group">
                {/* <input
                type="text"
                name="email"
                className="form-control"
                onChange={this.update}
                value={this.state.videoTitle}
                placeholder="your Email address"
              /> */}
                <input
                  type="text"
                  name="videoTitle"
                  className="form-control"
                  onChange={this.update}
                  value={this.state.videoTitle}
                  placeholder="Video title"
                />
                <input
                  type="text"
                  name="fileUrl"
                  className="form-control"
                  onChange={this.update}
                  value={this.state.fileUrl}
                  placeholder="File URL"
                />
                <button
                  type="button"
                  className={`btn btn-info ${styles.uploadButton}`}
                  onClick={this.transcribeFile}
                >
                  Transcribe your file
                </button>
                <div>
                  <span>
                    {this.state.uploadSuccess
                      ? "Congrats! your file is on the way."
                      : ""}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
