import React from "react";

const VideoSearch = ({ update, search, keyword }) => (
  <div>
    {/* <h1>Video library</h1> */}
    <div className="input-group mb-3">
      <input
        name="keyword"
        type="text"
        onChange={update}
        value={keyword}
        className="form-control"
        placeholder="Search keyword"
        aria-label="Search keyword"
        aria-describedby="button-addon2"
      />
      <div className="input-group-append">
        <button
          className="btn btn-info"
          type="button"
          id="button-addon2"
          onClick={search}
        >
          Search
        </button>
      </div>
    </div>
  </div>
);

export default VideoSearch;
