import React, { Component } from "react";
// import Navigation from "./components/Nav";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { authenticate } from "../../helpers";
import Auth from "../../helpers/auth";
import styles from "./Login.module.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      about: false,
    };
  }

  authenticate = async (e) => {
    e.preventDefault();
    this.setState({
      error: "",
    });
    var date = new Date();
    date.setTime(date.getTime() + 30 * 1000);

    await authenticate(this.state.email, this.state.password)
      .then((res) => {
        if (res.data.email) {
          Auth.login(() => {
            var date = new Date();
            date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
            var expires = "; expires=" + date.toGMTString();
            document.cookie = "email=" + res.data.email + expires + "; path=/";

            this.props.history.push("/library");
          });
        } else {
          this.setState({
            error: "Error: Something is wroing with your credentials...",
          });
        }
      })
      .catch((err) => {
        console.log("res", err);
      });
  };
  render() {
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function checkCookie() {
      var username = getCookie("email");
      if (username !== "") {
        return true;
      }
    }
    if (checkCookie()) {
      this.props.history.push("/library");
    }
    return (
      <>
        {/* <Navigation history={this.props.history} /> */}
        {this.state.about && (
          <div className={styles.aboutPopup}>
            <button
              type="button"
              className={styles.closeBtn}
              onClick={() => this.setState({ about: false })}
            >
              X
            </button>
            <div className={styles.container}>
              <div>
                <img
                  className={styles.h2oBlueLogo}
                  src="https://impactrepublic.github.io/h2o-blue/assets/images/h2o.png"
                  alt="he-logo"
                />
                <p>
                  H2O Blue is the speech recognition and search engine to
                  assistant for creators in developing content blueprints to
                  make accessible through search for collaboration and purchase.
                  Fast-Track Your Audio/Video Workflows with H20 Blue.
                </p>
                <p>
                  Go Beyond Transcription  and search with H20 Blue, The World's
                  Most Innovative Audio or video to Text Platform. 
                </p>
                <p>
                  Collaboration and searching through content is a breeze with
                  H20 Blue. Share your project with a web link, and grant
                  collaborators access to comment or edit. Add speaker labels in
                  seconds with our AI-powered Speaker Detective.
                </p>
                <p>
                  Safe and secure. Your data is confidential — even from us. H20
                  uses world-class technologies and services to keep your
                  information secure and private.
                </p>
                <p>
                  Cloud sync provides instant access from anywhere to you and
                  your collaborators — and full version history.
                </p>
                <p>
                  Already have an accurate transcription? We'll import it for
                  free and sync to your media, word for word.
                </p>
                <p>
                  Flexible document and subtitle export options. Customize
                  formatting, timestamps, and more.
                </p>
                <p>
                  H20 Blue is a collaborative audio/video editor that searchable
                  document for collaboration. It includes transcription, text to
                  speech, publishing, commerce and useful AI tools.
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={styles.loginView}>
          <Form
            className={styles.loginForm}
            onSubmit={(e) => this.authenticate(e)}
          >
            <img
              className={styles.h2oBlueLogo}
              src="https://impactrepublic.github.io/h2o-blue/assets/images/h2o.png"
              alt="he-logo"
            />
            {this.state.error ? (
              <small className="error-msg">{this.state.error}</small>
            ) : (
              ""
            )}
            <FormGroup>
              <Label for="exampleEmail" hidden>
                Email
              </Label>
              <Input
                type="email"
                name="email"
                id="exampleEmail"
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                placeholder="Email"
              />
            </FormGroup>{" "}
            <FormGroup>
              <Label for="examplePassword" hidden>
                Password
              </Label>
              <Input
                type="password"
                name="password"
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                id="examplePassword"
                placeholder="Password"
              />
            </FormGroup>{" "}
            <Button
              className={styles.loginBtn}
              type="button"
              onClick={(e) => this.authenticate(e)}
            >
              Login
            </Button>
            <p>
              Learn more{" "}
              <button
                type="button"
                onClick={() => this.setState({ about: true })}
              >
                About Us?
              </button>
            </p>
          </Form>
        </div>
      </>
    );
  }
}
export default Login;
