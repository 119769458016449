// import { useAuth0 } from "./react-auth0-spa";
import React from "react";
import Player from "./Player";
import Upload from "./views/Upload";
import Login from "./views/Login";
import Library from "./components/Library";
import { Switch, Route } from "react-router-dom";
// import Auth from "./helpers/auth";
import { ProtectedRoute } from "./helpers/protected-route";
// import Profile from "./components/Profile";
// import PrivateRoute from "./components/PrivateRoute";
// import { fireEvent } from "@testing-library/react";

export default function App({ history }) {
  return (
    <>
      <Switch>
        <ProtectedRoute exact path="/player/:id/:time?" component={Player} />
        <ProtectedRoute exact path="/upload" component={Upload} />
        <ProtectedRoute exact path="/library" component={Library} />
        <Route exact path="/" component={Login} />
        <Route path="*" componen={() => "404 NOT FOUND"} />
      </Switch>
    </>
  );
}
