import React from "react";
import { Link } from "react-router-dom";
import "./nav.css";
import Auth from "../../helpers/auth";
import { Nav, NavItem } from "reactstrap";

export const Navigation = ({ history }) => {
  return (
    <Nav className="no-print">
      <NavItem>
        <Link to="/">
          <img
            style={{ width: "100%", maxWidth: "28px" }}
            src="https://impactrepublic.github.io/h2o-blue/assets/images/h2o-icon.png"
            alt=""
          />{" "}
          <strong style={{ color: "#666" }}>H2O BLUE</strong>
        </Link>
      </NavItem>
      <NavItem>
        <Link to="/upload">upload</Link>
      </NavItem>
      <NavItem>
        <Link to="/library">Library</Link>
      </NavItem>

      {Auth.isAuthenticated() && (
        <NavItem style={{ position: "relative" }}>
          <button
            className="round-btn-inverted"
            onClick={() => {
              Auth.logout(() => {
                history.push("/");
              });
            }}
          >
            Logout
          </button>
        </NavItem>
      )}
    </Nav>
  );
};
