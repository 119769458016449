import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Slate, Editable, withReact, useSlate, useSelected } from "slate-react";
import isUrl from "is-url";
import {
  Text,
  createEditor,
  Editor,
  Transforms,
  Range,
  Node,
  findDOMNode,
} from "slate";
import { css } from "emotion";
import { withHistory } from "slate-history";
import { updateDBContent } from "../../helpers";
import { useParams } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import { isBackwards } from "../../helpers";
import isHotkey from "is-hotkey";
import { Button, Icon, Toolbar } from "./components-test";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+h": "highlight",
  "mod+l": "overline",
  "mod+s": "save",
  "mod+k": "link",
  "mod+`": "code",
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];

const updateVideo = (time, updateIndex) => {
  document.getElementById("video").currentTime = time;
  updateIndex();
};
let ui;
let previousTime;
const Transcription = ({
  content,
  video_id,
  keyword,
  updateIndex,
  inProcess,
  processCompleted,
  setPristine,
}) => {
  const save = (video, content) => {
    inProcess();
    return updateDBContent(video, content, processCompleted, setPristine);
  };
  const undo = (e) => {
    e.preventDefault();
    // if (editor.history.undos.length > 1) {
    //   editor.undo();
    // }
  };

  const redo = (e) => {
    e.preventDefault();
    // editor.redo();
  };
  //Get params from URL
  const { id, time } = useParams();
  ui = updateIndex;

  const [value, setValue] = useState(content);
  const [search, setSearch] = useState();
  const editor = useMemo(
    () => withLinks(withHistory(withReact(createEditor()))),
    []
  );
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  let timeStamp = document.querySelector(`[ts='${time}']`);
  if (timeStamp) {
    timeStamp.firstChild.classList.add("time-stamp");
  }
  const decorate = useCallback(
    ([node, path]) => {
      const ranges = [];
      renderLeaf();
      if (search && Text.isText(node)) {
        const { text } = node;

        const parts = text.split(search);
        let offset = 0;
        parts.forEach((part, i) => {
          if (i !== 0) {
            ranges.push({
              anchor: { path, offset: offset - search.length },
              focus: { path, offset },
              highlight: true,
            });
          }
          offset = offset + part.length + search.length;
        });
      }

      return ranges;
    },

    [search]
  );

  useEffect(() => {
    String.prototype.toHHMMSS = function () {
      var sec_num = parseInt(this, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds;
    };

    function getSelectionHtml() {
      var html = "";
      if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
          var container = document.createElement("div");
          for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
        }
      } else if (typeof document.selection != "undefined") {
        if (document.selection.type == "Text") {
          html = document.selection.createRange().htmlText;
        }
      }
      return html;
    }

    document.addEventListener("copy", function (e) {
      const selection = window.getSelection();
      let time, title, element, text, output;

      // Select the first word in selection
      if (!isBackwards()) {
        element = selection.baseNode;
      } else {
        element = selection.focusNode;
      }

      element = element.parentElement.parentElement;
      time = element.getAttribute("ts");

      title = element.closest("div").previousElementSibling;

      // If selection contains a title
      if (element.classList.contains("title")) {
        title = element.firstChild.innerText;

        // Remove title from body
        text = text = getSelectionHtml();
        output = `<a href='https://impactrepublic.github.io/he-transcription-fe/#/player/${id}/${time}'>[${time
          .toString()
          .toHHMMSS()}]</a> ${text}`;
      } else {
        let counter = 0;

        try {
          while (!title.querySelector(".title") && counter < 50) {
            counter++;
            title = title.previousElementSibling;
          }
          title = title.querySelector(".title span").innerText;
        } catch (err) {
          title = "Untitled";
        }

        // Leave body text as it is

        text = getSelectionHtml();

        output = `<a href='https://impactrepublic.github.io/he-transcription-fe/#/player/${id}/${time}'>[${time
          .toString()
          .toHHMMSS()}]</a><br/> ${text}`;
      }

      e.clipboardData.setData("text/html", output);

      e.preventDefault();
    });
  });

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(value) => {
        setPristine(false);
        return setValue(value);
      }}
    >
      <Toolbar className="no-print">
        <MarkButton format="undo" icon="undo" undo={undo} title="CMD+Z" />
        <MarkButton format="redo" icon="redo" redu={redo} title="CMD+SHIFT+Z" />
        <MarkButton
          format="bold"
          icon="format_bold"
          title="CMD+B"
          color="black"
        />
        <MarkButton
          format="italic"
          icon="format_italic"
          title="CMD+I"
          color="black"
        />
        <MarkButton format="underline" icon="format_underlined" title="CMD+U" />
        <MarkButton format="highlight" icon="format_color_text" title="CMD+H" />
        <MarkButton
          format="overline"
          icon="format_strikethrough"
          title="CMD+L"
        />
        <MarkButton format="link" icon="link" title="CMD+K" />

        <MaterialIcon icon="format_bold" style={{ display: "none" }} />
        {/* <BlockButton format="heading-two" icon="looks_two" />
        <BlockButton format="block-quote" icon="format_quote" />
        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" /> */}

        {/* <input
          id="search-input"
          type="text"
          placeholder="Keyword"
          // onChange={e => setSearch(e.target.value)}
          className={css`
            padding-left: 1em;
            outline: none;
            border: none;
            width: 100%;
          `}
        />
        <button
          className="search-btn"
          onClick={() =>
            setSearch(document.getElementById("search-input").value)
          }
        >
          Search
        </button> */}
        <button
          className="save-btn"
          onClick={() => save(video_id, value)}
          title="CMD+S"
        >
          Save
        </button>
      </Toolbar>
      <Editable
        id="editor"
        decorate={decorate}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={(event) => {
          let time = Editor.next(editor, editor.selection);

          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];

              if (mark === "save") {
                return save(video_id, value);
              }

              toggleMark(editor, mark);
            }
          }
          if (isHotkey("enter", event)) {
            event.preventDefault();

            const element = {
              type: "strong",
              children: [{ text: "Choose Title" }],
            };

            event.preventDefault();
            editor.insertNode(element);
            Transforms.setNodes(
              editor,
              { ts: time[0].ts, title: true },
              { match: (n) => Text.isText(n), split: true }
            );
          }
        }}
      />
    </Slate>
  );
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.title) {
    attributes.ts = leaf.time;
    attributes.style = {
      ...attributes.style,
      fontWeight: "bold",
      display: "block",
      color: "#0381b1",
      marginTop: 45,
    };
    attributes.className = "title";
  }

  if (leaf.bold) {
    attributes.style = { ...attributes.style, fontWeight: "bold" };
  }
  if (leaf.link) {
    attributes.style = {
      ...attributes.style,
      fontWeight: "bold",
      color: "rgb(3, 129, 230)",
    };
  }

  if (leaf.highlight) {
    attributes.style = {
      ...attributes.style,
      backgroundColor: "rgba(187, 187, 2, 0.36)",
      "-webkit-print-color-adjust": "exact",
    };
  }

  if (leaf.italic) {
    attributes.style = { ...attributes.style, fontStyle: "italic" };
  }

  if (leaf.underline) {
    attributes.style = { ...attributes.style, textDecoration: "underline" };
  }
  if (leaf.overline) {
    attributes.style = { ...attributes.style, textDecoration: "line-through" };
  }
  if (leaf.ts) {
    previousTime = leaf.ts;
  }
  if (leaf.link) {
    return (
      <a
        href={leaf.url}
        {...attributes}
        ts={leaf.ts || previousTime}
        onDoubleClick={(e) =>
          updateVideo(e.target.parentNode.getAttribute("ts"), () =>
            ui(e.target.parentNode.getAttribute("ts"))
          )
        }
      >
        {children}
      </a>
    );
  }
  return (
    <span
      {...attributes}
      ts={leaf.ts || previousTime}
      // end-ts={leaf.end_ts}
      onDoubleClick={(e) =>
        updateVideo(e.target.parentNode.getAttribute("ts"), () =>
          ui(e.target.parentNode.getAttribute("ts"))
        )
      }
    >
      {children}
    </span>
  );
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon, title, undo, redu, color = "black" }) => {
  const editor = useSlate();
  if (redu) {
    return (
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        title={title}
        onClick={redu}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  }
  if (undo) {
    return (
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        title={title}
        onClick={undo}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  }
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      title={title}
      style={{ color }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const Element = ({ attributes, children, element }) => {
  // console.log(children.props.node.children);
  // console.log(element);
  // switch (element.type) {
  //   case "block-quote":
  //     return <blockquote {...attributes}>{children}</blockquote>;
  //   case "bulleted-list":
  //     return <ul {...attributes}>{children}</ul>;
  //   case "heading-one":
  //     return <h1 {...attributes}>{children}</h1>;
  //   case "heading-two":
  //     return <h2 {...attributes}>{children}</h2>;
  //   case "list-item":
  //     return <li {...attributes}>{children}</li>;
  //   case "numbered-list":
  //     return <ol {...attributes}>{children}</ol>;
  //   default:
  if (element.type === "link") {
    return (
      <a {...attributes} href={element.url}>
        {children}
      </a>
    );
  }

  return <p {...attributes}>{children}</p>;

  // }
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === "link" });
  return !!link;
};

const unwrapLink = (editor) => {
  console.log("unwrap");
  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === "link",
    split: true,
  });
  Transforms.collapse(editor, { edge: "end" });
  // Transforms.collapse(editor, { edge: "end" });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };
  url ? (link.type = "link") : (link.type = "text");
  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const toggleMark = (editor, format) => {
  if (format === "link") {
    const url = window.prompt("Enter the URL of the link:");

    insertLink(editor, url);
  }
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export default Transcription;
